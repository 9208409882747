import {
  get,
  camelCase,
  debounce,
  isArray,
  isEmpty,
  isEqual,
  isNaN,
  isNil,
  toLower,
  toString as str,
  cloneDeep,
  uniqBy,
} from 'lodash'
import { showError, showClientNotifications, showValidationError } from 'helpers/errors'
import { mergeAll, isSignificant, DEBOUNCE } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { stopEvent } from 'helpers/events'
import Help from 'elements/Help'
import Modal from 'elements/Modal'
import isEmail from 'validator/lib/isEmail'

export const isReadOnly = (self) =>
  self?.props?.readOnly || self?.props?.parentRecord?.readOnly || self?.props?.rootRecord?.readOnly

export const filterByValue = (value, option) => {
  const text = option?.props?.children?.props?.children ?? option?.props?.children

  return toLower(text).indexOf(toLower(value)) > -1
}

export const validateUsername = () => ({
  validator: (rule, value, callback) => {
    callback(!isEmpty(value) && value !== encodeURIComponent(value) ? [new Error(t('invalidCharacter'))] : [])
  },
})

export const validateBarcode = () => ({
  validator: (rule, value, callback) => {
    callback(
      !isEmpty(value) && /^[a-zA-Z0-9-*_.$/+%\s]+$/.test(value) === false ? [new Error(t('invalidCharacter'))] : []
    )
  },
})

export const areEmails = (value) => {
  const emails = value.split(/;|\s/).filter(Boolean)
  return emails.every((one) => isEmail(one))
}

export const validateMultipleEmails = () => ({
  validator: (rule, value, callback) => {
    callback(!areEmails(value) ? [new Error(t('errorInvalidEmailAddress'))] : [])
  },
})

export const createDropdownRender = (data) => (menu) => {
  const recordCount = data?.recordCount ?? 0

  if (recordCount) {
    return (
      <div>
        {menu}
        <div style={{ padding: '8px', borderTop: '1px solid #d9d9d9' }}>
          {t('showing')} {Math.min(recordCount, 100)} {t('of')} {recordCount}
        </div>
      </div>
    )
  }

  return <div>{menu}</div>
}

const createIntegerValidator = (error) => (rule, value, callback) =>
  !isNil(value) && !isEmpty(value) && isNaN(Number.parseInt(str(value))) ? [new Error(error)] : []

const createFloatValidator = (error) => (rule, value, callback) =>
  !isNil(value) && !isEmpty(value) && isNaN(Number.parseFloat(str(value))) ? [new Error(error)] : []

export const createLabelFactory =
  (fieldSettings = []) =>
  (fieldName) => {
    if (!isArray(fieldSettings)) {
      throw new Error('fieldSettings is not an Array')
    }

    const dtoFieldName = fieldName.split('.').map(camelCase).join('.')
    const column = fieldSettings.find((one) => one.dtoFieldName === dtoFieldName)

    if (!isEmpty(fieldSettings) && isNil(column)) {
      console.error(`fieldSettings with dtoFieldName "${fieldName}" is not defined.`)
    }

    return (
      <span>
        {t(column?.recordLabelLanguageKey || column?.columnHeadingLanguageKey || dtoFieldName)}{' '}
        {column?.recordInfoLanguageKey && <Help title={t(column?.recordInfoLanguageKey)} />}
      </span>
    )
  }

export const createFieldFactory =
  (form, item, fieldSettings = []) =>
  (fieldName, { extraRules = [], ...rest } = {}) => {
    if (!isArray(fieldSettings)) {
      throw new Error('fieldSettings is not an Array')
    }

    const dtoFieldName = fieldName.split('.').map(camelCase).join('.')
    const column = fieldSettings.find((one) => one.dtoFieldName === dtoFieldName)

    if (!isEmpty(fieldSettings) && isEmpty(column)) {
      console.error(`fieldSettings with dtoFieldName "${dtoFieldName}" is not defined.`)
    }

    const rules = [...extraRules]

    if (column?.isRequired) {
      rules.push({
        required: true,
        message: t('requiredField'),
      })
    }

    if (column?.displayFormat === 'Email') {
      rules.push({
        type: 'email',
        message: t('errorInvalidEmailAddress'),
      })
    }

    if (column?.displayFormat === 'URL') {
      rules.push({
        type: 'url',
        message: t('invalidUrlEntered'),
      })
    }

    if (column?.displayFormat === 'Int') {
      rules.push({ validator: createIntegerValidator(t('invalidValueEntered')) })
    }

    if (column?.displayFormat && ['Float', 'Percent', 'Currency'].some((one) => column.displayFormat.startsWith(one))) {
      rules.push({ validator: createFloatValidator(t('invalidValueEntered')) })
    }

    if (column?.maxLength) {
      rules.push({
        max: column.maxLength,
        message: t('errorInputTooLong'),
      })
    }

    return form.getFieldDecorator(dtoFieldName, {
      rules,
      initialValue: get(item, fieldName),
      validateTrigger: false,
      ...rest,
    })
  }

export const createHandleSubmit = (self) => {
  const readOnly = isReadOnly(self)
  const onSaveHandler = self.onSaveHandler ?? self.props.onSave
  const onSaveAndCloseHandler = self.onSaveAndCloseHandler ?? self.props.onSaveAndClose

  if (!readOnly && !onSaveHandler) {
    console.error('FormView is missing onSave handler')
  }

  if (!readOnly && !onSaveAndCloseHandler) {
    console.error('FormView is missing onSaveAndClose handler')
  }

  return async (
    e,
    { saveAndClose = true, saveButtonLoading = saveAndClose ? 'saveAndCloseButtonLoading' : 'saveButtonLoading' } = {}
  ) => {
    stopEvent(e)

    if (readOnly) {
      console.error('handleSubmit is called when form view is read-only.')
      return
    }

    try {
      await self.beforeValidateFields?.()
    } catch (error) {
      showError({ error })
      return
    }

    self.props.form.validateFields((errors, values) => {
      self.setState({ validationErrors: errors }, async () => {
        const {
          createItem = (data) => Promise.resolve({ value: { data } }),
          updateItem = (data) => Promise.resolve({ value: { data } }),
        } = self.props

        if (!isEmpty(errors)) {
          showValidationError()
          return
        }

        let savedItem = null

        try {
          const { item = {} } = self.state
          self.setState({ [saveButtonLoading]: true }, () => onSaveHandler?.(true, item))

          const saveItem = self.saveItem ? self.saveItem : item.id ? updateItem : createItem
          const params = mergeAll({}, item, values) // NOTE: Using merge due to nested dtoFieldNames
          const saveItemParams = {
            ...params,
            ...(self.transformSaveItemParams ? self.transformSaveItemParams({ item, values, params }) : {}),
            saveAndClose,
          }

          const response = await saveItem(saveItemParams)

          savedItem =
            response.value.data.successCount && !isEmpty(response.value.data.items)
              ? cloneDeep(response.value.data.items[0])
              : cloneDeep(response.value.data)

          if (isNil(savedItem)) {
            throw new Error('Invalid save response!')
          }

          showClientNotifications({ response })

          if (response.value.data.failureCount > 0) {
            throw new Error()
          }

          if (self.saveItemSuccess) {
            await self.saveItemSuccess(savedItem, saveItemParams)
          }

          await onSaveHandler?.(false, savedItem)

          if (saveAndClose) {
            await onSaveAndCloseHandler?.(savedItem)
          } else {
            self.setState({ item: null }, () => self.setState({ item: savedItem }, () => self.fetchItem()))
          }
        } catch (error) {
          showError({ error })
          onSaveHandler?.(false, savedItem)
        } finally {
          self.setState({ [saveButtonLoading]: false })
        }
      })
    })
  }
}

export const createChildItemsDispatchToProps = (dispatch, childName, actions) => ({
  [`get${childName}Items`]: (parentId, params) => dispatch(actions.getChildItems(parentId, params)),
  [`download${childName}Items`]: (parentId, params) => dispatch(actions.downloadChildItems(parentId, params)),
  [`create${childName}Items`]: (parentId, items) => dispatch(actions.createChildItems(parentId, items)),
  [`update${childName}Items`]: (parentId, items) => dispatch(actions.updateChildItems(parentId, items)),
  [`delete${childName}Items`]: (parentId, items) => dispatch(actions.deleteChildItems(parentId, items)),
})

export const createLinkedItemsDispatchToProps = (dispatch, entityName, actions) => ({
  [`get${entityName}Items`]: (params) =>
    dispatch(
      actions.getItems({
        ...params,
        pageIndex: 0,
      })
    ),
  [`download${entityName}Items`]: (params) => dispatch(actions.downloadItems(params)),
  [`create${entityName}Items`]: (items) => dispatch(actions.createItems(items)),
  [`update${entityName}Items`]: (items) => dispatch(actions.updateItems(items)),
  [`delete${entityName}Items`]: (items) => dispatch(actions.deleteItems(items)),
})

export const createDocumentsDispatchToProps = (dispatch, documentActions) => ({
  getDocumentContents: (params) => dispatch(documentActions.getContents(params)),
  getDocumentItems: (params) =>
    dispatch(
      documentActions.getItems({
        ...params,
        pageIndex: 0,
      })
    ),
  createDocumentItems: (params) => dispatch(documentActions.createItems(params)),
  deleteDocumentItems: (params) => dispatch(documentActions.deleteItems(params)),
})

export const getChangedItems = (originals = [], items = []) => {
  const itemIds = items.map((each) => each.id)
  const originalIds = originals.map((each) => each.id)
  const creating = items.filter((each) => !originalIds.includes(each.id)).map(({ id, ...rest }) => ({ ...rest }))
  const updating = items.filter((each) => {
    const original = originals.find((one) => one.id === each.id)
    return original && !isEqual(original, each)
  })
  const deleting = originals.filter((each) => !itemIds.includes(each.id))

  return {
    creating, // creating items don't have id
    updating: uniqBy(updating, 'id'),
    deleting: uniqBy(deleting, 'id'),
  }
}

export const createSaveDocumentItems = (self, domainObjectType) => async (domainObjectId) => {
  const { deleting } = getChangedItems(self.state.documentItemsOriginal, self.state.documentItems)
  const uploading = self.state.documentItems.filter((each) => each.fileContents)
  const documentType = 'ObjectDocument'

  if (!isEmpty(deleting)) {
    const response = await self.props.deleteDocumentItems(
      deleting.map((each) => ({
        domainObjectId,
        domainObjectType,
        documentType,
        documentName: each.fileName,
      }))
    )

    showClientNotifications({ response })

    if (response.value.data.failureCount > 0) {
      throw new Error()
    }
  }

  if (!isEmpty(uploading)) {
    const response = await self.props.createDocumentItems(
      uploading.map((each) => ({
        domainObjectId,
        domainObjectType,
        documentType,
        fileName: each.fileName,
        fileType: each.fileType,
        contents: each.fileContents,
      }))
    )

    showClientNotifications({ response })

    if (response.value.data.failureCount > 0) {
      throw new Error()
    }
  }
}

export const saveImageFileExtensions = {
  'image/png': '.png',
  'image/jpeg': '.jpg',
  'image/gif': '.gif',
}

export const createSaveImage =
  (self, domainObjectType, documentType = 'ObjectImage') =>
  async (domainObjectId, fileNameWithoutExtension) => {
    const { image, imageOriginal } = self.state

    if (!isEqual(image, imageOriginal) && imageOriginal?.fileName) {
      const response = await self.props.deleteDocumentItems([
        {
          domainObjectId,
          domainObjectType,
          documentType,
          documentName: imageOriginal.fileName,
        },
      ])

      showClientNotifications({ response })

      if (response.value.data.failureCount > 0) {
        throw new Error()
      }
    }

    if (!isEqual(image, imageOriginal) && image?.fileType && image?.contents) {
      const response = await self.props.createDocumentItems([
        {
          domainObjectId,
          domainObjectType,
          documentType,
          fileName: `${fileNameWithoutExtension}${str(get(saveImageFileExtensions, image.fileType))}`,
          fileType: image.fileType,
          contents: image.contents,
        },
      ])

      showClientNotifications({ response })

      if (response.value.data.failureCount > 0) {
        throw new Error()
      }
    }
  }

export const createSaveWarrantyItems = (self) => async (assetId) => {
  const { creating, updating, deleting } = getChangedItems(self.state.warrantyItemsOriginal, self.state.warrantyItems)

  if (!isEmpty(deleting)) {
    const response = await self.props.deleteWarrantyItems(
      assetId,
      deleting.map((each) => each.id)
    )

    showClientNotifications({ response })

    if (response.value.data.failureCount > 0) {
      throw new Error()
    }
  }

  if (!isEmpty(updating)) {
    const response = await self.props.updateWarrantyItems(
      assetId,
      updating.map((each) => ({ ...each, assetId }))
    )

    showClientNotifications({ response })

    if (response.value.data.failureCount > 0) {
      throw new Error()
    }
  }

  if (!isEmpty(creating)) {
    const response = await self.props.createWarrantyItems(
      assetId,
      creating.map(({ id, assetWarrantyId, ...rest }) => ({
        ...rest,
        assetId,
      }))
    )

    showClientNotifications({ response })

    if (response.value.data.failureCount > 0) {
      throw new Error()
    }
  }
}

export const createSaveToleranceItems = (self) => async (assetId) => {
  const { creating, updating, deleting } = getChangedItems(self.state.toleranceItemsOriginal, self.state.toleranceItems)

  if (!isEmpty(deleting)) {
    const response = await self.props.deleteToleranceItems(
      assetId,
      deleting.map((each) => each.id)
    )

    showClientNotifications({ response })

    if (response.value.data.failureCount > 0) {
      throw new Error()
    }
  }

  if (!isEmpty(updating)) {
    const response = await self.props.updateToleranceItems(
      assetId,
      updating.map((each) => ({ ...each, assetId }))
    )

    showClientNotifications({ response })

    if (response.value.data.failureCount > 0) {
      throw new Error()
    }
  }

  if (!isEmpty(creating)) {
    const response = await self.props.createToleranceItems(
      assetId,
      creating.map(({ id, assetToleranceId, ...rest }) => ({
        ...rest,
        assetId,
      }))
    )

    showClientNotifications({ response })

    if (response.value.data.failureCount > 0) {
      throw new Error()
    }
  }
}

export const createSaveToleranceHistoryItems = (self) => async (assetId) => {
  const { updating } = getChangedItems(self.state.toleranceItemsOriginal, self.state.toleranceItems)

  if (!isEmpty(updating)) {
    const response = await self.props.createTolerancesHistoryItems(
      assetId,
      updating.map((each) => ({
        id: 0,
        assetId,
        toleranceId: each.id,
        entryDate: new Date().toJSON(),
        userName: self.props.user.userName,
        value: each.newValue,
        min: each.min,
        max: each.max,
        notify: each.notify,
        notifySent: false,
      }))
    )

    showClientNotifications({ response })

    if (response.value.data.failureCount > 0) {
      throw new Error()
    }
  }
}

export const createSearchJobs = (self) =>
  debounce(async (search) => {
    try {
      const { jobId, jobIds = [] } = self.props.filterDto ?? {}

      const response = await self.props.getJobs({
        search,
        jobIds: jobId ? [jobId] : jobIds,
      })

      self.setState?.({ jobs: response.value.data })
    } catch (error) {
      showError({ error })
    } finally {
      self.setState?.({ searching: false })
    }
  }, DEBOUNCE)

export const createSearchOperators = (self) =>
  debounce(async (search) => {
    try {
      const {
        operatorGroupIds = [],
        operatorIds = [],
        assignedToId,
        operatorId,
        assignedOperatorIds = [],
      } = self.props.filterDto ?? {}

      const response = await self.props.getOperators({
        search,
        operatorGroupIds,
        operatorIds: assignedToId ? [assignedToId] : operatorId ? [operatorId] : assignedOperatorIds || operatorIds,
      })

      self.setState?.({ operators: response.value.data })
    } catch (error) {
      showError({ error })
    } finally {
      self.setState?.({ searching: false })
    }
  }, DEBOUNCE)

export const createSearchCustomerGroups = (self) =>
  debounce(async (search) => {
    try {
      const { customerGroupId, customerGroupIds = [] } = self.props.filterDto ?? {}

      const response = await self.props.getCustomerGroups({
        search,
        customerGroupIds: customerGroupId ? [customerGroupId] : customerGroupIds,
      })

      self.setState?.({ customerGroups: response.value.data })
    } catch (error) {
      showError({ error })
    } finally {
      self.setState?.({ searching: false })
    }
  }, DEBOUNCE)

export const createSearchFormBatches = (self) =>
  debounce(async (search) => {
    try {
      const { formBatchId, formBatchIds = [] } = self.props.filterDto ?? {}

      const response = await self.props.getFormBatches({
        search,
        formBatchIds: formBatchId ? [formBatchId] : formBatchIds,
      })

      self.setState?.({ formBatches: response.value.data })
    } catch (error) {
      showError({ error })
    } finally {
      self.setState?.({ searching: false })
    }
  }, DEBOUNCE)

export const createSearchFormTemplates = (self) =>
  debounce(async (search) => {
    try {
      const { formTemplateId, formTemplateIds = [] } = self.props.filterDto ?? {}

      const response = await self.props.getFormTemplates({
        search,
        formTemplateIds: formTemplateId ? [formTemplateId] : formTemplateIds,
      })

      self.setState?.({ formTemplates: response.value.data })
    } catch (error) {
      showError({ error })
    } finally {
      self.setState?.({ searching: false })
    }
  }, DEBOUNCE)

export const createSearchAssets = (self) =>
  debounce(async (search) => {
    try {
      const possibleIds = [
        self.props.assetId,
        self.props.assetIds,
        self.props.jobsForAssetId,
        self.props.jobsForAssetIds,
        self.props.filterDto?.assetId,
        self.props.filterDto?.assetIds,
        self.props.filterDto?.jobsForAssetId,
        self.props.filterDto?.jobsForAssetIds,
        self.state?.item?.assetId,
        self.state?.item?.assetIds,
        self.state?.item?.jobsForAssetId,
        self.state?.item?.jobsForAssetIds,
      ]
      const assetId = possibleIds.find(isSignificant)
      const assetIds = isArray(assetId) ? assetId : !isNil(assetId) ? [assetId] : []
      const assetBarcodes = [
        self.props.assetBarcodes,
        self.props.filterDto?.assetBarcodes,
        self.state?.item?.assetBarcodes,
      ].find(isSignificant)

      const response = await self.props.getAssets({
        search,
        assetIds,
        assetBarcodes,
        assetCategoryIds: self.props.filterDto?.assetCategoryIds ?? [],
      })

      self.setState?.({ assets: response.value.data })
    } catch (error) {
      showError({ error })
    } finally {
      self.setState?.({ searching: false })
    }
  }, DEBOUNCE)

export const createSearchProducts = (self) =>
  debounce(async (search) => {
    try {
      const possibleIds = [
        self.props.productId,
        self.props.productIds,
        self.props.filterDto?.productId,
        self.props.filterDto?.productIds,
        self.state?.item?.productId,
        self.state?.item?.productIds,
      ]
      const productId = possibleIds.find(isSignificant)
      const productIds = isArray(productId) ? productId : !isNil(productId) ? [productId] : []
      const productBarcodes = [
        self.props.productBarcodes,
        self.props.filterDto?.productBarcodes,
        self.state?.item?.productBarcodes,
      ].find(isSignificant)

      const response = await self.props.getProducts({
        search,
        productIds,
        productBarcodes,
        productCategoryIds: self.props.filterDto?.productCategoryIds ?? [],
      })

      self.setState?.({ products: response.value.data })
    } catch (error) {
      showError({ error })
    } finally {
      self.setState?.({ searching: false })
    }
  }, DEBOUNCE)

export const createSearchSuppliers = (self) =>
  debounce(async (search) => {
    try {
      const possibleSupplierIds = [
        self.props.supplierId,
        self.props.supplierIds,
        self.props.preferredSupplierId,
        self.props.preferredSupplierIds,
        self.props.defaultSupplierId,
        self.props.defaultSupplierIds,
        self.props.orderSupplierId,
        self.props.orderSupplierIds,
        self.props.filterDto?.supplierId,
        self.props.filterDto?.supplierIds,
        self.props.filterDto?.preferredSupplierId,
        self.props.filterDto?.preferredSupplierIds,
        self.props.filterDto?.defaultSupplierId,
        self.props.filterDto?.defaultSupplierIds,
        self.props.filterDto?.orderSupplierId,
        self.props.filterDto?.orderSupplierIds,
        self.state?.item?.supplierId,
        self.state?.item?.supplierIds,
        self.state?.item?.preferredSupplierId,
        self.state?.item?.preferredSupplierIds,
        self.state?.item?.defaultSupplierId,
        self.state?.item?.defaultSupplierIds,
        self.state?.item?.orderSupplierId,
        self.state?.item?.orderSupplierIds,
      ]
      const supplierId = possibleSupplierIds.find(isSignificant)
      const supplierIds = isArray(supplierId) ? supplierId : !isNil(supplierId) ? [supplierId] : []
      const { includeInactiveSuppliers, supplierRecordStatus = 'All' } = self.props.filterDto ?? {}

      const response = await self.props.getSuppliers({
        search,
        supplierIds,
        active: includeInactiveSuppliers ? 'All' : supplierRecordStatus,
      })

      self.setState?.({ suppliers: response.value.data })
    } catch (error) {
      showError({ error })
    } finally {
      self.setState?.({ searching: false })
    }
  }, DEBOUNCE)

export const createSearchOperatorTag = (self) =>
  debounce(async (search) => {
    try {
      self.setState({ searching: true, operator: null, operatorId: null })

      const response = await self.props.getOperators({
        search,
        searchType: 'Contains',
      })

      self.setState({ operators: response.value.data })
    } catch (error) {
      showError({ error })
    } finally {
      self.setState({ searching: false })
    }
  }, DEBOUNCE)

export const createSearchJobTag = (self) =>
  debounce(async (search) => {
    try {
      self.setState({ searching: true, job: null, jobId: null })

      const response = await self.props.getJobs({
        search,
        searchType: 'Contains',
      })

      self.setState({ jobs: response.value.data })
    } catch (error) {
      showError({ error })
    } finally {
      self.setState({ searching: false })
    }
  }, DEBOUNCE)

export const createSearchAssetTag = (self) =>
  debounce(async (search) => {
    try {
      self.setState({ searching: true, asset: null, assetId: null })

      const response = await self.props.getAssets({
        search,
        searchType: 'Contains',
      })

      self.setState({ assets: response.value.data })
    } catch (error) {
      showError({ error })
    } finally {
      self.setState({ searching: false })
    }
  }, DEBOUNCE)

export const confirmUnsavedChanges = (onOk = () => {}) =>
  Modal.confirm({
    autoFocusButton: 'ok',
    title: t('unsavedChangesConfirmTitle'),
    content: t('doYouWishToContinue'),
    okText: t('continue'),
    okType: 'danger',
    cancelText: t('cancel'),
    onOk,
  })
